import React, { useEffect, useState, useRef } from "react";
import "./RandomQuote.css";
import twitter_icon from "../Assets/twitter-x.png";
import reload_icon from "../Assets/reload.png";

const RandomQuote = () => {
  const quotes = useRef([]); // Use a ref for the quotes array
  const [quote, setQuote] = useState({ text: "", author: "" });

  useEffect(() => {
    const Papa = require("papaparse");

    Papa.parse("https://raw.githubusercontent.com/IconoclastXYZ/Quotes/refs/heads/main/Intelekt_Quotes.csv", {
      download: true,
      complete: function (result) {
        console.log(result);

        quotes.current = result.data;
        getRandomQuote();
      },
    });
  }, []);

  const getRandomQuote = () => {
    if (quotes.current.length === 0) return; // Ensure quotes array is not empty
    const rngBasedQuote = Math.floor(Math.random() * quotes.current.length);
    setQuote({
      text: quotes.current[rngBasedQuote][0], // Assuming quote text is in the first column
      author: quotes.current[rngBasedQuote][1], // Assuming author is in the second column
    });
  };

  const twitter = () => {
    const tweetText = `${quote.text} - ${quote.author}`;
    const tweetUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl, "_blank");
  };

  return (
    <div className="base">
      <div className="container">
        <div className="quote">{quote.text}</div>
        <div>
          <div className="line"></div>
          <div className="bottom">
            <div className="author">{quote.author}</div>
            <div className="icons">
              <img src={reload_icon} onClick={getRandomQuote} alt="reload" id="reload_button" />
              <img src={twitter_icon} alt="twitter" onClick={twitter} id="twitter_x" />
            </div>
          </div>
        </div>
      </div>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js" data-require="jquery@3.1.1" data-semver="3.1.1" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/PapaParse/4.6.1/papaparse.min.js" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/d3/4.13.0/d3.min.js" />
    </div>
  );
};

export default RandomQuote;
