import './App.css';
import RandomQuote from './Components/Random Quote/RandomQuote';

function App() {
  return (
    <div>
      <RandomQuote />
    </div>
  );
}

export default App;
